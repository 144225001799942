<template>
  <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
    <form v-on:submit.prevent="handleSubmit(next)" class="p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Discount Type</label>
                <select
                  name="state"
                  v-model="newSettlement.discount_type"
                  class="form-control"
                  required                                 
                >
                  <option value="">Select Discount Type</option>
                  <option value="percent">Percent (%)</option>
                  <option value="fixed">Fixed Amount</option>
                  >
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Discount Value</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="newSettlement.discount_value"
                  placeholder="Discount value"
                  required                 
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Total Amount <small>(with discount)</small></label>
                <input
                  type="number"
                  class="form-control"
                  :value="discountTotal"                 
                  disabled
                  required
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Is EMI?</label>
                <div class="row">
                  <div class="checkbox col-md-4 pl-4">
                    <label>
                      <input
                        type="radio"
                        name="is_emi"
                        v-model="newSettlement.is_emi"
                        :value="true"
                      />
                      Yes
                    </label>
                  </div>

                  <div class="checkbox col-md-4">
                    <label>
                      <input
                        type="radio"
                        name="is_emi"
                        v-model="newSettlement.is_emi"
                        :value="false"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4" v-if="newSettlement.is_emi">
              <div class="form-group">
                <label>Installation Period</label>
                <select
                  name="area"
                  v-model="newSettlement.installment_period"
                  class="form-control"
                  required
                >
                  <option value="">Select Period</option>
                  <option value="MONTHLY">Monthly</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-4" v-if="newSettlement.is_emi">
              <div class="form-group">
                <label>No of Installation</label>
                <select
                  name="area"
                  v-model="newSettlement.number_of_installment"
                  class="form-control"
                  required
                >
                  <option value="">Select One</option>
                  <option :value="3">3</option>
                  <option :value="4">4</option>
                  <option :value="5">5</option>
                  <option :value="6">6</option>
                  <option :value="8">8</option>
                  <option :value="10">10</option>
                  <option :value="12">12</option>
                </select>
              </div>
            </div>

            <div class="col-md-4" v-if="newSettlement.is_emi">
              <div class="form-group">
                <label>EMI Cycle Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="newSettlement.start_date"
                  placeholder="YYYY-MM-DD"
                  :min="minDate"
                  required
                />
              </div>
            </div>     

            <div class="col-md-4">
              <div class="form-group">
                <label>Proposed By</label>
                <!-- <input
                  type="text"
                  class="form-control"
                  v-model="newSettlement.proposed_by"
                  placeholder="Who proposed this discount or emi?"
                  required
                /> -->
                <select class="form-control" v-model="newSettlement.proposed_by">
                  <option value="">Select One</option>
                  <option value="Mayor">Mayor</option>
                  <option value="Chief Engineer">Chief Engineer</option>
                  <option value="Executive Engineer">Executive Engineer</option>
                  <option value="Assistant Engineer">Assistant Engineer</option>
                </select>
              </div>
            </div>     

          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-default" @click="back()">
            Back
          </button>
          <button type="submit" class="btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
export default {
  name: "DiscountInfo",
  props: {
    settlement: Object,
    activeTab: Number,
  },
  data() {
    return {
      newSettlement: {},   
      startTotal: 0,
      minDate: moment().format("YYYY-MM-DD")    
    };
  },
  computed: {    
    discountTotal() {      
      if(this.newSettlement.discount_type === 'percent') {
        return (this.startTotal - (this.startTotal * (this.newSettlement.discount_value/100))).toFixed(2);
      } else {
        return (this.startTotal - this.newSettlement.discount_value).toFixed(2);
      }
    }
  },
  methods: {
    back() {
      this.$emit("formSubmitted", {
        data: this.newSettlement,
        tab: 1,
      });
    },
    next() {
      this.$emit("formSubmitted", {
        data: this.newSettlement,
        tab: 3,
      });
    },      
  },
  mounted() {
    this.newSettlement = { ...this.settlement };
    this.startTotal = this.newSettlement.total_amount;    
  },
};
</script>
